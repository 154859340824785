<template>
  <div>
    <v-card outlined tile>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>폴더</v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-icon>
          <v-btn text
            @click="dialog = true"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
      <v-list>
        <v-list-item-group v-model="itemIndex" color="primary">
          <v-list-item @click="selectFolder('ALL', 0)">
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                전체
                <span v-if="mediaItemsCnt['all']">({{ mediaItemsCnt['all'] }})</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-for="(item, i) in folders"
            :key="i"
            @click="selectFolder(item.id, i + 1)"
          >
            <v-list-item-icon>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ item.name }}
                <span v-if="mediaItemsCnt[item.id]">({{ mediaItemsCnt[item.id] }})</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>

    <add-folder-card
      :dialog="dialog"
      :folders="folders"
      :mediaItemsCnt="mediaItemsCnt"
      @closeDialog="closeDialog"
    ></add-folder-card>
  </div>
</template>
<script>
import AddFolderCard from '@/components/widgets/AddFolderCard'

export default {
  props: ['folders', 'mediaItemsCnt', 'folderIndex'],
  components: { AddFolderCard },
  data () {
    return {
      dialog: false,
      itemIndex: 0
    }
  },
  created () {
    this.itemIndex = this.folderIndex
  },
  methods: {
    selectFolder (id, idx) {
      this.$emit('getItemList', id, idx)
    },
    closeDialog (b) {
      this.dialog = false
    }
  }
}
</script>
