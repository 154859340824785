<template>
  <div>
    <v-dialog
      :value="dialog"
      width="600"
      persistent
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          폴더 추가
          <v-spacer/>
          <v-btn text small @click="closeDialog"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-list-item class="my-3">
          <v-text-field
            v-model="dialogFolder"
            placeholder="폴더명을 입력하세요"
            @keydown.enter="addFolder"
          ></v-text-field>
          <v-btn color="primary" tile outlined class="ml-3" @click="addFolder">추가</v-btn>
        </v-list-item>

        <v-card outlined tile class="text-center" v-if="folders.length <= 0">
          <v-sheet class="py-5">등록된 폴더가 없습니다</v-sheet>
        </v-card>
        <v-card class="scroll" height="380" outlined tile v-else>
          <v-card-text>
              <add-folder-card-item
                v-for="(folder, i) in folders"
                :key="i"
                :folder="folder"
                :mediaItemsCnt="mediaItemsCnt"
                :dialog="dialog"
              ></add-folder-card-item>
          </v-card-text>
        </v-card>

      </v-card>
    </v-dialog>
  </div>

</template>
<script>
import AddFolderCardItem from '@/components/widgets/AddFolderCardItem'

export default {
  props: ['dialog', 'folders', 'mediaItemsCnt'],
  components: { AddFolderCardItem },
  data () {
    return {
      dialogFolder: ''
    }
  },
  methods: {
    closeDialog () {
      this.$emit('closeDialog', false)
    },
    addFolder () {
      if (this.dialogFolder === null) return
      if (this.dialogFolder.trim().length <= 0) {
        this.dialogFolder = ''
        return
      }

      const academyid = this.$store.state.user.uid
      const item = {
        name: this.dialogFolder
      }

      try {
        this.$firebase.firestore().collection('academies').doc(academyid)
          .collection('folders').add(item)
          .then(ref => {
          // console.log('addStudent', ref.id)
          })
      } catch (e) {
        console.log(e.message)
      } finally {
        this.dialogFolder = ''
        this.$emit('closeDialog', false)
      }
    }
  }
}
</script>

<style scoped>
.scroll {
  overflow-y: scroll
}
</style>
