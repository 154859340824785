<template>
  <div>
    <!-- <page-header :tabsItems="tabsItems" :title="title"></page-header> -->

    <v-container v-if="!loaded" fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile>
            <v-skeleton-loader type="card-heading"></v-skeleton-loader>
          </v-card>
          <v-row>
            <v-col cols="4">
              <v-skeleton-loader type="list-item" v-for="i in 3" :key="i"></v-skeleton-loader>
            </v-col>
            <v-col cols="8">
              <v-skeleton-loader type="list-item-avatar-three-line" v-for="i in 3" :key="i"></v-skeleton-loader>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-card outlined tile class="mb-3">
            <v-list-item>
              <v-list-item-action>
                <v-checkbox
                  v-model="allSelected"
                ></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-if="selectedItems.length > 0">
                  <div>
                  선택 ({{ selectedItems.length }})
                  <span>
                    <v-menu
                      offset-y
                      close-on-content-click
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          text
                          v-bind="attrs"
                          v-on="on"
                        >
                          이동 &#9660;
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item
                          v-for="(item, index) in folders"
                          :key="index"
                          @click="moveToFolder(item.id, item.name)"
                        >
                          <v-list-item-title>{{ item.name }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-btn
                      color="error"
                      text
                      @click="delItems"
                    >삭제</v-btn>
                  </span>
                  </div>
                </v-list-item-title>
                <v-list-item-title v-else>
                  [{{ currentFolder }}] 폴더
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-btn class="d-none d-md-flex" color="light-green" tile dark @click="addItem"><v-icon>mdi-plus</v-icon> 신규등록</v-btn>
                <v-btn class="d-flex d-md-none" color="light-green" tile dark small @click="addItem"><v-icon>mdi-plus</v-icon></v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-card>

          <v-row>
            <v-col cols="4">
              <folders-card
                :folders="folders"
                :mediaItemsCnt="mediaItemsCnt"
                :folderIndex="folderIndex"
                @getItemList="getItemList"
              ></folders-card>
            </v-col>

            <v-col cols="8">
              <v-card outlined tile height="120" v-if="mediaItems.length <= 0">
                <v-list-item>
                  <v-list-item-title class="d-flex justify-center mt-13">
                    등록된 미디어가 없습니다.
                  </v-list-item-title>
                </v-list-item>
              </v-card>
              <div v-else>
                <media-item
                  class="pb-3"
                  v-for="(item, index) in mediaItems"
                  :key="index"
                  :mediaItem="item"
                  :allSelected="allSelected"
                  :clear="clearItemCheck"
                  @setItem="setItem"
                  @resetItem="resetItem"
                  @editItem="editItem"
                  @delItem="delItem"
                  @preview="preview"
                  @previewPDF="previewPDF"
                ></media-item>
              </div>
              <v-row >
                <v-col cols="12" class="pt-0">
                  <v-card outlined tile v-if="this.paging.currentPage < this.paging.totalPage">
                    <v-btn block tile text @click="more">
                      더보기({{ this.paging.currentPage }} / {{ this.paging.totalPage }})<v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-col>
      </v-row>
    </v-container>

    <v-bottom-sheet max-width="650"
      v-model="previewAudioDialog"
      inset
      open-delay
    >
      <v-card tile>
        <v-progress-linear
          :value="100"
          class="my-0"
          height="3"
        ></v-progress-linear>
        <v-list>
          <v-list-item>
            <audio controls autoplay id="audioControl" controlsList="nodownload" style="width:100%"></audio>
          </v-list-item>

          <v-virtual-scroll
            :items="selectedPreviewItem.audioFiles"
            :item-height="57"
            height="230"
            class="mt-3"
          >
            <template v-slot="{ index, item }">
              <v-list-item
                style="border-bottom: 1px solid #d3d3d3"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ index + 1 }}. {{ item.title }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    fab
                    x-small
                    color="info"
                    dark
                    @click="playAudioFile(index)"
                  ><v-icon>mdi-play</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-list>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet max-width="650"
      v-model="previewVideoDialog"
      inset
      open-delay
    >
      <v-card tile>
        <v-progress-linear
          :value="100"
          class="my-0"
          height="3"
        ></v-progress-linear>
        <v-list>
          <v-list-item>
            <youtube :video-id="playbackYoutubeId" ref="youtube" @playing="playing"></youtube>
          </v-list-item>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>{{ selectedPreviewItem.title }}</v-list-item-title>
              <v-list-item-subtitle>{{ selectedPreviewItem.folder }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>
<script>
// import PageHeader from '../PageHeader'
import FoldersCard from '@/components/widgets/FoldersCard'
import MediaItem from '@/components/widgets/MediaItemCard'
import { last } from 'lodash'
const LIMIT = 30

export default {
  components: { FoldersCard, MediaItem },
  data () {
    return {
      title: 'Repository',
      tabsItems: [
        { id: 1, title: 'Library', link: '/repo/library' }
        // { id: 2, title: 'Quest', link: '/repo/quest' }
      ],
      allSelected: false,
      sortType: '폴더이동',
      mediaItems: [],
      mediaItemsCnt: {},
      folders: [],
      foldersCnt: 0,
      folderIndex: 0,
      listItems: [],
      selectedItems: [],
      currentFolder: '',
      clearItemCheck: '',
      loaded: false,
      previewAudioDialog: false,
      previewVideoDialog: false,
      playbackYoutubeId: '',
      selectedPreviewItem: {},
      ref: null,
      unsubscribe: null,
      paging: {
        currentPage: 1,
        sizePerPage: LIMIT,
        totalPage: 1,
        lastVisible: null
      }
    }
  },
  watch: {
    previewAudioDialog () {
      if (!this.previewAudioDialog) {
        const audio = document.getElementById('audioControl')
        if (audio) {
          audio.pause()
          audio.currentTime = 0
          audio.src = ''
        }
        // console.log('audio unload')
      }
    },
    previewVideoDialog () {
      if (!this.previewVideoDialog) {
        this.playbackYoutubeId = ''
        // console.log('video unload')
      }
    }
  },
  created () {
    this.read()
  },
  computed: {
    player () {
      return this.$refs.youtube.player
    },
    academyid () {
      return this.$store.state.user.uid
    }
  },
  methods: {
    read () {
      this.getFolders()
      this.getItemList('ALL', 0)
      this.getMediaCnt()
    },
    moveToFolder (folderId, folderName) {
      const msg = '[' + folderName + '] 폴더로 이동하시겠습니까?'
      this.$swal.fire({
        title: msg,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: '취소',
        confirmButtonText: '네, 이동합니다',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          if (this.selectedItems.length > 0) {
            this.selectedItems.forEach((n) => {
              this.$firebase.firestore().collection('libraries').doc(n).update({
                folder: folderId
              })
            })
          }
          this.clearItemCheck = new Date().getTime()

          this.selectedItems = []
          this.allSelected = false
        }
      })
    },
    addItem () {
      this.$router.push('/repo/library/add')
    },
    editItem (selItem) {
      // console.log('editItem : ', selItem)
      this.$router.push({ name: 'libraryEdit', params: { selItem: selItem } })
    },
    delItem (selItem) {
      try {
        const msg = '"' + selItem.title + '" 파일을 삭제하시겠습니까?'

        this.$swal.fire({
          title: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '취소',
          confirmButtonText: '네, 삭제합니다',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.$firebase.firestore().collection('libraries').doc(selItem.id).delete()

            this.clearItemCheck = new Date().getTime()
          }
        })
      } catch (e) {
        console.log(e.message)
      }
    },
    delItems () {
      try {
        const msg = this.selectedItems.length + ' 개 파일을 삭제하시겠습니까?'

        this.$swal.fire({
          title: msg,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: '취소',
          confirmButtonText: '네, 삭제합니다',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.selectedItems.length > 0) {
              this.selectedItems.forEach((n) => {
                this.$firebase.firestore().collection('libraries').doc(n).delete()
              })
            }
            this.clearItemCheck = new Date().getTime()

            this.selectedItems = []
            this.allSelected = false
          }
        })
      } catch (e) {
        console.log(e.message)
      }
    },
    getFolders () {
      this.$firebase.firestore().collection('academies').doc(this.academyid).collection('folders')
        .orderBy('name')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.folders = []
            return
          }

          this.foldersCnt = sn.size

          this.folders = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              name: item.name
            }
          })
          // console.log('###', JSON.stringify(this.folders))
        })
    },
    async getMediaCnt () {
      await this.$firebase.firestore().collection('academies').doc(this.academyid)
        .collection('infos').doc('media')
        .onSnapshot((sn) => {
          if (sn.empty) {
            this.mediaItemsCnt = {}
          } else {
            this.mediaItemsCnt = sn.data()
            this.paging.totalPage = this.mediaItemsCnt.all

            if (this.mediaItemsCnt.all) {
              this.paging.totalPage = parseInt(this.mediaItemsCnt.all / this.paging.sizePerPage)
              const r = this.mediaItemsCnt.all % this.paging.sizePerPage
              if (r > 0) {
                this.paging.totalPage = this.paging.totalPage + 1
              }
            }
            // console.log(this.mediaItemsCnt)
          }
        })
    },
    getItemList (folderId, idx) {
      this.loaded = false
      this.folderIndex = idx

      if (folderId === 'ALL') {
        this.currentFolder = '전체'

        this.ref = this.$firebase.firestore().collection('libraries')
          .where('academy', '==', this.academyid)
          .orderBy('title')
          .limit(LIMIT)

        this.unsubscribe = this.ref.onSnapshot((sn) => {
          this.loaded = true
          if (sn.empty) {
            this.mediaItems = []
            this.resetPage()
            return
          }

          this.setPage('all')
          this.paging.lastVisible = sn.docs[sn.docs.length - 1]

          this.mediaItems = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              title: item.title,
              type: item.type,
              // folder: item.folder,
              folderId: item.folder,
              folder: this.getFolderName(item.folder),
              imgName: item.imageName,
              imgUrl: item.imageUrl,
              audioFiles: item.audioFiles,
              pdfFilename: item.pdfFilename,
              pdfFileUrl: item.pdfUrl,
              yUrl: item.youtubeUrl,
              dur: item.duration
            }
          })
          // console.log('###', JSON.stringify(this.mediaItems))
        })
      } else {
        this.currentFolder = this.getFolderName(folderId)

        this.ref = this.$firebase.firestore().collection('libraries')
          .where('academy', '==', this.academyid)
          .where('folder', '==', folderId)
          .orderBy('title')
          .limit(LIMIT)

        this.unsubscribe = this.ref.onSnapshot((sn) => {
          this.loaded = true
          if (sn.empty) {
            this.mediaItems = []
            this.resetPage()
            return
          }

          this.setPage(folderId)

          this.paging.lastVisible = sn.docs[sn.docs.length - 1]

          this.mediaItems = sn.docs.map(v => {
            const item = v.data()
            return {
              id: v.id,
              title: item.title,
              type: item.type,
              // folder: item.folder,
              folderId: item.folder,
              folder: this.getFolderName(item.folder),
              imgName: item.imageName,
              imgUrl: item.imageUrl,
              audioFiles: item.audioFiles,
              pdfFilename: item.pdfFilename,
              pdfFileUrl: item.pdfUrl,
              yUrl: item.youtubeUrl,
              dur: item.duration
            }
          })
          // console.log('###', JSON.stringify(this.mediaItems))
        })
      }
      this.selectedItems = []
      this.allSelected = false
    },
    setPage (folderId) {
      this.paging.currentPage = 1
      this.paging.totalPage = this.mediaItemsCnt[folderId]

      if (this.mediaItemsCnt[folderId]) {
        this.paging.totalPage = parseInt(this.mediaItemsCnt[folderId] / this.paging.sizePerPage)
        const r = this.mediaItemsCnt[folderId] % this.paging.sizePerPage
        if (r > 0) {
          this.paging.totalPage = this.paging.totalPage + 1
        }
      }
    },
    resetPage () {
      this.paging.currentPage = 1
      this.paging.sizePerPage = LIMIT
      this.paging.totalPage = 1
      this.paging.lastVisible = null
    },
    setItem (id) {
      this.selectedItems = this.selectedItems.filter(function (n) {
        return n !== id
      })
      this.selectedItems.push(id)
    },
    resetItem (id) {
      this.selectedItems = this.selectedItems.filter(function (n) {
        return n !== id
      })
    },
    preview (item) {
      // console.log('preview : ', item)
      this.selectedPreviewItem = item
      if (item.type === 'A') {
        this.previewAudioDialog = true
      } else {
        this.previewVideoDialog = true
        this.playbackYoutubeId = this.getVideoId(item.yUrl)
      }
    },
    previewPDF (item) {
      if (item.pdfFileUrl) window.open(item.pdfFileUrl, 'OpenPDF')
    },
    getVideoId (url) {
      if (!url) return ''

      const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
      const match = url.match(regExp)
      return (match && match[7].length === 11) ? match[7] : false
    },
    async playVideo () {
      await this.player.playVideo()
    },
    playing () {
      // console.log('we are watching!!!')
    },
    async more () {
      if (this.paging.currentPage >= this.paging.totalPage) return

      const sn = await this.ref.startAfter(this.paging.lastVisible).get()
      this.paging.currentPage = this.paging.currentPage + 1
      this.paging.lastVisible = last(sn.docs)

      sn.docs.forEach(doc => {
        const item = doc.data()
        const newItem = {
          id: doc.id,
          title: item.title,
          type: item.type,
          // folder: item.folder,
          folderId: item.folder,
          folder: this.getFolderName(item.folder),
          imgName: item.imageName,
          imgUrl: item.imageUrl,
          // aFilename: item.audioFilename,
          // aFileUrl: item.audioUrl,
          audioFiles: item.audioFiles,
          pdfFilename: item.pdfFilename,
          pdfFileUrl: item.pdfUrl,
          yUrl: item.youtubeUrl,
          dur: item.duration
        }
        this.mediaItems.push(newItem)
      })
    },
    playAudioFile (index) {
      const audio = document.getElementById('audioControl')
      audio.pause()
      audio.currentTime = 0
      audio.src = ''
      if (this.selectedPreviewItem.audioFiles.length <= 0) return

      audio.src = this.selectedPreviewItem.audioFiles[index].url
      audio.load()
    },
    getFolderName (id) {
      const fn = this.folders.find(f => f.id === id)
      if (fn === undefined) return ''
      else return fn.name
    }
  }
}
</script>
