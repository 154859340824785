<template>
  <div>
      <v-list-item>
        <v-list-item-icon><v-icon>mdi-folder</v-icon></v-list-item-icon>
        <v-list-item-content>
          <v-text-field
            hide-details
            dense
            outlined
            clearable
            v-model="modifiedFolderName"
            @keydown.enter="updateFolder(folder.id, folder.name)"
            v-if="modeModify"></v-text-field>
          <v-list-item-title
            v-text="folder.name"
            v-else></v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <v-btn
            color="primary"
            text
            @click="updateFolder(folder.id, folder.name)"
            v-if="modeModify">저장</v-btn>
          <v-btn
            color="primary"
            text
            @click="modifyFolder(folder.id, folder.name)"
            v-else>수정</v-btn>
        </v-list-item-action>
        <v-list-item-action>
          <v-btn
            text
            @click="cancelModify()"
            v-if="modeModify">취소</v-btn>
          <v-btn
            color="error"
            text
            @click="delFolder(folder.id, folder.name)"
            v-else>삭제</v-btn>
        </v-list-item-action>
      </v-list-item>
  </div>
</template>

<script>
export default {
  props: ['dialog', 'folder', 'mediaItemsCnt'],
  data () {
    return {
      // dialog: false,
      dialogFolder: '',
      modeModify: false,
      modifiedFolderName: ''
      // ////////
    }
  },
  watch: {
    dialog () {
      if (this.dialog) {
        this.modeModify = false
      }
    }
  },
  methods: {
    closeDialog () {
      this.modeModify = false
      this.modifiedFolderName = ''
      this.$emit('closeDialog', false)
    },
    async delFolder (id, name) {
      // 폴더에 포함된 아이템 갯수는 0 이어야 삭제 가능
      // console.log(id, this.mediaItemsCnt[name])
      if (this.mediaItemsCnt[id] > 0) {
        this.$swal.fire({
          icon: 'error',
          title: '폴더에 등록된 미디어가 있습니다.'
        })
        return
      }
      // console.log(id, this.mediaItemsCnt)
      const academyid = this.$store.state.user.uid
      try {
        await this.$firebase.firestore().collection('academies').doc(academyid)
          .collection('folders').doc(id).delete()
      } catch (e) {
        console.log(e.message)
      }
    },
    async modifyFolder (id, name) {
      this.modeModify = true
      this.modifiedFolderName = name
    },
    cancelModify () {
      this.modeModify = false
      this.modifiedFolderName = ''
    },
    async updateFolder (id, name) {
      if (this.modifiedFolderName === null) return
      if (this.modifiedFolderName.trim().length <= 0) {
        this.modifiedFolderName = ''
        return
      }
      const academyid = this.$store.state.user.uid
      try {
        await this.$firebase.firestore().collection('academies').doc(academyid)
          .collection('folders').doc(id).update({
            name: this.modifiedFolderName
          })
      } catch (e) {
        console.log(e.message)
      }
      this.modeModify = false
    }
  }
}
</script>
